<template>
  <div class="child-container detail">
    <img class="head-img" :src="detailData.imgList[0].url" alt="">
    <section class="detail-content">
      <div class="detail-title flex">
        <div>{{detailData.title}}</div>
        <div class="detail-type" @click="$router.push({ path: '/' })">
          <span class="el-icon-menu"></span>
          <span class="ml-15">{{filterList.find(item => item.type == detailData.type) && filterList.find(item => item.type == detailData.type).name}}</span>
        </div>
      </div>
      <div class="detail-des" v-html="detailData.des1"></div>
      <div class="detail-des" v-html="detailData.des2"></div>

      <div class="img-content">
        <el-image lazy class="img-item" v-for="(item, index) in detailData.imgList.filter((n, i) => i != 0)" :key="index + 'image'" :src="item.url"></el-image>
      </div>

      <div class="detail-subtitle">OTHER WORKS</div>
      <el-carousel :interval="interval" height="326px" class="carousel">
        <el-carousel-item v-for="(bannerItem, bannerIndex) in bannerList" :key="bannerIndex + 'bannerx'">
          <div class="proj-content">
            <div class="proj-item" v-for="(item, index) in bannerItem" :key="bannerIndex + 'projx' + index">
              <img :src="item.imgList[0].url" alt width="387px" height="258px" @click="toDetail(item)">
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </section>
  </div>
</template>

<script>
import { filterList } from '../utils/Const'
export default {
  name: "Detail",
  components: {},
  data() {
    return {
      filterList,
      detailData: {
        imgList: [
          { url: '' }
        ]
      },
      bannerList: [],
      interval: 4000
    };
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail() {
      const { _id } = this.$route.query
      this.$http.get(`/project/${_id}`).then((res) => {
        this.detailData = res.data || {
        imgList: [
          { url: '' }
        ]
      },
        this.getOtherList()
      });
    },
    getOtherList() {
      const { type, _id } = this.detailData
      this.bannerList = []
      this.$http.post(`/project/other`, { _id, pageSize: 12 }).then((res) => {
        res.data.list.forEach((item, i) => {
          const index = Math.floor(i/3)
          if (!this.bannerList[index]) {
            this.bannerList.push([])
          }
          this.bannerList[index].push(item)
        });
      });
    },
    toDetail({ _id }) {
      this.$router.push({ path: "/detail", query: { _id } });
      this.getDetail()
      document.querySelector('.el-scrollbar__wrap').scrollTop = 0
    }
    
  }
};
</script>

<style lang="less" scoped>
.detail {
  padding-bottom: 100px;
  margin-top: -256px;
}
.head-img {
  width: 100%;
}
.detail-content {
  max-width: 1200px;
  width: 100%;
  margin: auto;
}
.detail-title {
  font-family: PingFangSC-Medium;
  font-size: 30px;
  color: rgba(0,0,0,0.85);
  font-weight: 500;
  margin-top: 100px;
  margin-bottom: 24px;
  text-align: left;
  justify-content: space-between;
}
.detail-type {
  cursor: pointer;
  &:hover {
    font-weight: bold;
    color: #000000;
  }
}
.ml-15 {
  margin-left: 15px;
}
.detail-des {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: rgba(0,0,0,0.85);
  font-weight: 400;
  text-align: justify;
  + .detail-des {
    margin-top: 12px;
  }
}
.img-content {
  margin-bottom: 20px;
  .img-item {
    width: 100%;
    margin-top: 110px;
    > img {
      width: 100%;
    }
  }
}

.detail-subtitle {
  margin-top: 110px;
  margin-bottom: 30px;
  text-align: center;
  font-family: PingFangSC-Medium;
  font-size: 30px;
  color: #000000;
  font-weight: 500;
}


/deep/ .proj-content {
  display: flex;
  .proj-item {
    text-align: center;
    img {
      object-fit: fill;
    }
    + .proj-item {
      margin-left: 19px;
    }
  }
}

/deep/ .el-carousel--horizontal {
  .el-carousel__indicators {
    bottom: 0;
  }
  .el-carousel__arrow {
    margin-top: -20px;
  }
  .el-carousel__button {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: rgba(0,0,0,0.8);
    margin: 0 14px;
  }
}
</style>
